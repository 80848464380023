import { useState, useEffect, useRef, useContext } from "react"
import useRaf from "@rooks/use-raf"
import * as THREE from "three"
import { followPath } from "../three/camera/cameraMovement"
import { TransformControls } from "../three/controls/controls"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
// import { OrbitControls } from "../three/camera/OrbitWithLookAround.js"
import { modelLoader } from "../three/loaders/loaders"
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper.js"
import { VertexTangentsHelper } from "three/examples/jsm/helpers/VertexTangentsHelper.js"
import { followKeyframes } from "../three/camera/cameraGLBMovement"
import {
  EffectComposer,
  ShaderPass,
  BokehPass,
  RenderPass,
} from "../three/postProcessing/postProcessing"
import { FXAAShader } from "../three/shaders/shaders"

// Import Global Context(s)
import LoadingContext from "~context/loading"

// import global hooks
import useRenderer from "~hooks/useRenderer"
import usePlayheadPosition from "~hooks/usePlayheadPosition"
import { useWindowSize } from "react-use"

// import higher order components
import withComposition from "~higherOrderComponents/withComposition"
/* Dynamic Import to Resolve Build Issues */
const dat = typeof window !== `undefined` ? require("dat.gui") : null

let controls
let mouseDown = 0

const NervousSystemLaying = ({ startTime, endTime, editorMode = false }) => {
  // Context
  const { setNervousSystemCompositionLoading } = useContext(LoadingContext)

  // State
  const [initialTime, setInitialTime] = useState()
  const [isReady, setIsReady] = useState(false)

  // Hooks
  const playheadPosition = usePlayheadPosition()
  const renderer = useRenderer()
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  // References
  const cameraReference = useRef()
  const sceneReference = useRef()
  const clockReference = useRef()
  const effectComposerReference = useRef()
  const mixerReference = useRef(null)
  const counterReference = useRef(0)
  const planeReference = useRef(null)
  const plane2Reference = useRef(null)
  const guiReference = useRef()
  const nervoMeshReference = useRef(null)
  const vertexNormalsReference = useRef(null)
  const knotReference1 = useRef()
  const knotReference2 = useRef()
  const knotReference3 = useRef()

  // Constants
  const isActive =
    (!startTime && !endTime) ||
    (playheadPosition >= startTime && playheadPosition <= endTime)
  const cameraCurve = [
    new THREE.Vector3(
      19.526340349517838,
      6.364637510336584,
      30.451513400414207
    ),
    new THREE.Vector3(
      13.016619204016505,
      10.045524442350143,
      -8.397697087619939
    ),
    new THREE.Vector3(
      -21.955675115761046,
      17.187816910004564,
      -21.393096401521433
    ),
    new THREE.Vector3(
      -39.2968699395061,
      4.865918600383043,
      -4.1135238962027305
    ),
    new THREE.Vector3(
      -29.859115288045135,
      1.5838594543438447,
      34.66603979753403
    ),
  ]

  useEffect(() => {
    if (isReady) {
      setNervousSystemCompositionLoading(false)
    }
  }, [isReady, setNervousSystemCompositionLoading])

  useEffect(() => {
    if (isReady && isActive && !initialTime) {
      setInitialTime(playheadPosition)
    }
  }, [isReady, isActive, playheadPosition, initialTime])

  useEffect(() => {
    if (cameraReference.current && effectComposerReference.current) {
      const camera = cameraReference.current
      camera.aspect = windowWidth / windowHeight
      camera.updateProjectionMatrix()
      effectComposerReference.current.setSize(windowWidth, windowHeight)
    }
  }, [windowWidth, windowHeight])

  useEffect(() => {
    const initialize = async () => {
      /* Constants and References */
      cameraReference.current = new THREE.PerspectiveCamera(
        75,
        windowWidth / windowHeight,
        0.1,
        1000
      )
      sceneReference.current = new THREE.Scene()
      clockReference.current = new THREE.Clock()
      effectComposerReference.current = new EffectComposer(renderer)
      if (editorMode) {
        guiReference.current = new dat.GUI()
      }
      const camera = cameraReference.current
      const scene = sceneReference.current
      const effectComposer = effectComposerReference.current

      /* 
        Configure Effect Composers 
      */

      camera.position.z = 2
      camera.position.y = 1.5
      // camera.position.x = 1.5

      /* 
        Configure Effect Composers 
      */

      /* Load and Configure Nervous System */
      let nervousSystemGltfData
      try {
        nervousSystemGltfData = await modelLoader("nervo_embrace.glb")
        console.log(nervousSystemGltfData)
      } catch (error) {
        throw new Error(error)
      }

      scene.add(nervousSystemGltfData.scene)

      const nervoControls = new TransformControls(camera, renderer.domElement)
      nervoControls.attach(nervousSystemGltfData.scene)
      console.log(nervoControls)
      nervoControls.enabled = false
      // scene.add(nervoControls)

      mixerReference.current = new THREE.AnimationMixer(
        nervousSystemGltfData.scene
      )
      const clip = THREE.AnimationClip.findByName(
        nervousSystemGltfData.animations,
        "nervo_mocap"
      )
      const animationStartTime = Math.floor(Date.now() / 1000) % clip.duration // note - current clip.duration is 65
      const action = mixerReference.current.clipAction(clip)
      action.time = animationStartTime
      action.play()
      let boxSet = false
      let objCount = 0
      nervousSystemGltfData.scene.position.y -= 0.75
      nervousSystemGltfData.scene.rotation.y = Math.PI
      nervousSystemGltfData.scene.traverse(function (object) {
        if (object.isMesh) {
          object.castShadow = true
          object.material.metalness = 0
          object.material.wireframe = true
          object.frustumCulled = false
          if (boxSet == false && objCount == 5) {
            scene.add(new THREE.BoxHelper(object))
            boxSet = true
            object.geometry.computeTangents() // generates bad data due to degenerate UVs
            nervoMeshReference.current = object
            // const group = new THREE.Group();
            // group.scale.multiplyScalar( 50 );
            // scene.add( group );

            // // To make sure that the matrixWorld is up to date for the boxhelpers
            // group.updateMatrixWorld( true );

            // group.add( mesh );

            // physicsHelper = helper.objects.get( mesh ).physics.createHelper();
            // physicsHelper.visible = false;
            // scene.add( physicsHelper );

            let vnh = new VertexNormalsHelper(object, 0.01)
            scene.add(vnh)
            vertexNormalsReference.current = vnh

            let vth = new VertexTangentsHelper(object, 0.01)
            scene.add(vth)
          }
          objCount += 1
          // console.log(objCount)
        }
      })

      // Old white out skybox using sphere
      const spheregeometry = new THREE.SphereGeometry(55, 32, 16)
      const spherematerial = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
      })
      const sphere = new THREE.Mesh(spheregeometry, spherematerial)
      // scene.add(sphere)

      //  Turn on Orbit Controls
      // if (editorMode) {
      //   controls = new OrbitControls(camera, renderer.domElement)
      // }

      // SKYBOX skybox
      const cubeTextureLoader = new THREE.CubeTextureLoader()
      const skyTexture = cubeTextureLoader.load([
        "/nyio/cubemaps/px.jpg",
        "/nyio/cubemaps/nx.jpg",
        "/nyio/cubemaps/py.jpg",
        "/nyio/cubemaps/ny.jpg",
        "/nyio/cubemaps/pz.jpg",
        "/nyio/cubemaps/nz.jpg",
      ])
      scene.background = skyTexture

      // const groundGeometry = setGeom(new THREE.PlaneGeometry( 5, 5, 10, 10 ), 0x000000, 0, 0, 0);
      // const groundMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00, wireframe: true} );
      // const groundPlane = new THREE.Mesh( groundGeometry, groundMaterial );
      // // groundPlane.rotation.x = Math.PI/2
      // scene.add( groundPlane );

      function setGeom(g, color, x, y, z) {
        ToQuads(g)
        let m = new THREE.LineBasicMaterial({
          color: color,
        })
        let o = new THREE.LineSegments(g, m)
        o.position.set(x, y, z)
        o.rotation.x = Math.PI / 2
        scene.add(o)
      }

      function ToQuads(g) {
        let p = g.parameters
        let segmentsX =
          (g.type == "TorusBufferGeometry"
            ? p.tubularSegments
            : p.radialSegments) ||
          p.widthSegments ||
          p.thetaSegments ||
          p.points.length - 1 ||
          1
        let segmentsY =
          (g.type == "TorusBufferGeometry"
            ? p.radialSegments
            : p.tubularSegments) ||
          p.heightSegments ||
          p.phiSegments ||
          p.segments ||
          1
        let indices = []
        for (let i = 0; i < segmentsY + 1; i++) {
          let index11 = 0
          let index12 = 0
          for (let j = 0; j < segmentsX; j++) {
            index11 = (segmentsX + 1) * i + j
            index12 = index11 + 1
            let index21 = index11
            let index22 = index11 + (segmentsX + 1)
            indices.push(index11, index12)
            if (index22 < (segmentsX + 1) * (segmentsY + 1) - 1) {
              indices.push(index21, index22)
            }
          }
          if (
            index12 + segmentsX + 1 <=
            (segmentsX + 1) * (segmentsY + 1) - 1
          ) {
            indices.push(index12, index12 + segmentsX + 1)
          }
        }
        g.setIndex(indices)
      }

      /* Create Lights */

      let light = new THREE.DirectionalLight(0xdba342, 0.8)
      light.position.set(10, 10, 10)
      light.target.position.set(0, 0, 0)
      light.castShadow = true
      light.shadow.bias = -0.001
      light.shadow.mapSize.width = 2048
      light.shadow.mapSize.height = 2048
      light.shadow.camera.near = 0.1
      light.shadow.camera.far = 500.0
      light.shadow.camera.left = 10
      light.shadow.camera.right = -10
      light.shadow.camera.top = 10
      light.shadow.camera.bottom = -10
      light.intensity = 0.21 * 4
      scene.add(light)

      let light2 = new THREE.DirectionalLight(0xdba342)
      light2.position.set(-10, 10, -10)
      light2.target.position.set(0, 0, 0)
      light2.castShadow = true
      light2.shadow.bias = -0.001
      light2.shadow.mapSize.width = 2048
      light2.shadow.mapSize.height = 2048
      light2.shadow.camera.near = 0.1
      light2.shadow.camera.far = 500.0
      light2.shadow.camera.left = 10
      light2.shadow.camera.right = -10
      light2.shadow.camera.top = 10
      light2.shadow.camera.bottom = -10
      light2.intensity = 0.21
      scene.add(light2)

      const ambient_light = new THREE.AmbientLight(0x808080)
      scene.add(ambient_light)

      /* Configuyre Planes that Receive Shadows */
      const planeGeometry = new THREE.PlaneGeometry(1, 1, 32, 32)
      const planeMaterial = new THREE.MeshStandardMaterial({ color: 0xadd8e6 })
      const planeGeometry2 = new THREE.PlaneGeometry(1, 1, 32, 32)
      const planeMaterial2 = new THREE.MeshStandardMaterial({ color: 0xadd8e6 })
      planeReference.current = new THREE.Mesh(planeGeometry, planeMaterial)
      plane2Reference.current = new THREE.Mesh(planeGeometry2, planeMaterial2)

      let texture
      try {
        texture = await new THREE.TextureLoader().load(
          "/nyio/textures/flame.png"
        )
        console.log("Flame Texture loaded")
      } catch (error) {
        throw new Error(error)
      }
      texture.repeat.set(1 / 16, 1 / 8)
      texture.offset.set(0, 0)
      planeReference.current.material.map = texture
      planeReference.current.position.set(0, 1, 0)
      planeReference.current.rotation.set(0, 0, 0, "XYZ")
      planeReference.current.material.transparent = true
      let texture2
      try {
        texture2 = await new THREE.TextureLoader().load(
          "/nyio/textures/flame2.png"
        )
        console.log("Flame Texture loaded")
      } catch (error) {
        throw new Error(error)
      }
      texture2.repeat.set(1 / 16, 1 / 8)
      texture2.offset.set(8 / 16, 3 / 8)
      plane2Reference.current.material.map = texture2
      plane2Reference.current.position.set(0.01, 1, 0.1)
      plane2Reference.current.rotation.set(0, 0, 0, "XYZ")
      plane2Reference.current.material.transparent = true

      /* Configure Controls */
      const transformControls = new TransformControls(
        camera,
        renderer.domElement
      )
      //   transformControls.addEventListener("dragging-changed", function (event) {
      //     if (controls) controls.enabled = !event.value
      //   })
      transformControls.attach(light)
      // scene.add(transformControls)

      /* 
        Configure Effect Composers 
      */

      /* Configure Render Passes */
      effectComposer.addPass(new RenderPass(scene, camera))

      /* Configure Bokeh Pass */
      // const bokehPass = new BokehPass(scene, camera, {
      //   focus: 21.5,
      //   aperture: 0.001,
      //   maxblur: 0.005,
      // })
      // effectComposer.addPass(bokehPass)

      /* Configure FxaaPass */
      const fxaaPass = new ShaderPass(FXAAShader)
      const pixelRatio = renderer.getPixelRatio()
      const uniforms = fxaaPass.material.uniforms
      uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio)
      uniforms["resolution"].value.y = 1 / (window.innerHeight * pixelRatio)

      /* Configure Dat.Gui */
      // if (editorMode) {
      // Add the dat-gui
      // const gui = guiReference.current
      // gui.domElement.style.zIndex = "10000"

      // // Light Controls
      // let guiLights
      // guiLights = gui.addFolder("Lights")
      // guiLights.add(light.position, "x", -20, 20).name("TargetX")

      // Bokeh Controls
      // let addBokehPass = true
      // let bokehParams = {
      //   aperature: 0.01,
      //   aspect: 1.7,
      //   farClip: 1000,
      //   focus: 21.5,
      //   maxblur: 0.005,
      //   nearClip: 0.1,
      //   pass: true,
      // }

      // let guiBokeh = gui.addFolder("Bokeh")
      // guiBokeh.add(bokehParams, "pass").onChange(function (e) {
      //   if (e) {
      //     addBokehPass = true
      //     effectComposer.addPass(bokehPass)
      //   } else {
      //     addBokehPass = false
      //     effectComposer.removePass(bokehPass)
      //   }
      // })
      // guiBokeh.add(bokehPass.uniforms.focus, "value", 0, 200).name("Focus")
      // guiBokeh
      //   .add(bokehPass.uniforms.aperture, "value", 0, 1)
      //   .name("Aperture")
      // guiBokeh.add(bokehPass.uniforms.aspect, "value", 0, 50).name("Aspect")
      // guiBokeh
      //   .add(bokehPass.uniforms.farClip, "value", 0, 2000)
      //   .name("Far Clip")
      // guiBokeh
      //   .add(bokehPass.uniforms.nearClip, "value", 0, 1)
      //   .name("Near Clip")
      // guiBokeh.add(bokehPass.uniforms.maxblur, "value", 0, 1).name("Max Blur")

      function generateSprite() {
        var canvas = document.createElement("canvas")
        canvas.width = 16
        canvas.height = 16

        var context = canvas.getContext("2d")
        var gradient = context.createRadialGradient(
          canvas.width / 2,
          canvas.height / 2,
          0,
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2
        )
        gradient.addColorStop(0, "rgba(255,255,255,1)")
        gradient.addColorStop(0.2, "rgba(100,0,0,1)")
        gradient.addColorStop(0.4, "rgba(64,0,0,1)")
        gradient.addColorStop(1, "rgba(255,0,0,1)")

        context.fillStyle = gradient
        context.fillRect(0, 0, canvas.width, canvas.height)

        var texture = new THREE.Texture(canvas)
        texture.needsUpdate = true
        return texture
      }

      function createPoints(geom) {
        var material = new THREE.PointsMaterial({
          color: 0xffffff,
          size: 0.4,
          transparent: true,
          blending: THREE.AdditiveBlending,
          map: generateSprite(),
          depthWrite: false, // instead of sortParticles
        })

        var cloud = new THREE.Points(geom, material)
        return cloud
      }

      var geom = new THREE.TorusKnotGeometry(15, 39.7, 33, 4)
      console.log(geom)
      // var geom = new THREE.TorusKnotGeometry(15/2, 39.7/2, 14, 2);

      knotReference1.current = createPoints(geom)
      knotReference1.current.scale.x = 0.5
      knotReference1.current.scale.y = 0.5
      knotReference1.current.scale.z = 0.5
      knotReference1.current.position.y = -10

      knotReference2.current = createPoints(geom)
      knotReference2.current.position.x = -20
      knotReference2.current.position.y = 20
      knotReference1.current.scale.x = 0.5
      knotReference1.current.scale.y = 0.5
      knotReference1.current.scale.z = 0.5

      knotReference3.current = createPoints(geom)
      knotReference3.current.position.x = 20
      knotReference1.current.scale.x = 0.5
      knotReference1.current.scale.y = 0.5
      knotReference1.current.scale.z = 0.5

      // add it to the scene.
      scene.add(knotReference1.current)
      scene.add(knotReference2.current)
      scene.add(knotReference3.current)

      // Export Settings
      let exportParams = {
        exportSettings: () => {
          prompt("Copy these settings")
        },
      }
      // gui.add(exportParams, "exportSettings")
      // }

      /* Done Configuring Scene */
      setIsReady(true)
    }

    initialize()
  }, [])

  /* Configure Update Function */

  let mouseX = 0
  let mouseY = 0
  let prevMouseX = 0
  let prevMouseY = 0
  let deltaX = 0
  let deltaY = 0
  let camY = 0

  addEventListener("mousedown", event => {
    mouseDown = 1
  })

  addEventListener("mouseup", event => {
    mouseDown = 0
  })

  const update = () => {
    // constants
    const camera = cameraReference.current
    const plane = planeReference.current
    const plane2 = plane2Reference.current

    document.onmousemove = onMouseMove

    function onMouseMove(event) {
      // if(mouseDown === 0) {
      // console.log("MOVING")
      mouseX = event.clientX
      mouseY = event.clientY
      deltaX = mouseX - prevMouseX
      deltaY = mouseY - prevMouseY

      prevMouseX = mouseX
      prevMouseY = mouseY

      // Max turn
      if (Math.abs(deltaX) > 100) deltaX = deltaX / Math.abs(deltaX)
      if (Math.abs(deltaY) > 100) deltaY = deltaY / Math.abs(deltaY)

      // let x = cameraReference.current.rotation.x
      // let y = cameraReference.current.rotation.y
      // let z = cameraReference.current.rotation.z
      // cameraReference.current.rotation.set(x - deltaY/1000, y - deltaX/1000, z, 'YXZ')
      // console.log(cameraReference.current)

      // Initial camera position
      let camX = cameraReference.current.position.x
      let camY = cameraReference.current.position.y
      let camZ = cameraReference.current.position.z

      // Distance from camera to origin
      let camMag = Math.sqrt(camX ** 2 + camY ** 2 + camZ ** 2)

      let camTh = Math.atan2(camZ, camX)
      let xzMag = Math.sqrt(camX ** 2 + camZ ** 2)
      let camPhi = Math.atan2(camY, xzMag)
      camPhi += deltaY / 500

      xzMag = camMag * Math.cos(camPhi)
      camY = camMag * Math.sin(camPhi)
      camZ = xzMag * Math.sin(camTh)
      camX = xzMag * Math.cos(camTh)
      camMag = Math.sqrt(camX ** 2 + camY ** 2 + camZ ** 2)
      camTh = Math.atan2(camZ, camX)

      camTh += deltaX / 500
      cameraReference.current.position.x = xzMag * Math.cos(camTh)
      cameraReference.current.position.z = xzMag * Math.sin(camTh)
      cameraReference.current.position.y = camMag * Math.sin(camPhi)
    }
    cameraReference.current.lookAt(0, 1, 0)

    knotReference1.current.rotation.y -= 0.001
    knotReference1.current.position.y += 0.001

    knotReference2.current.rotation.y -= 0.0005
    knotReference2.current.position.y -= 0.001

    knotReference3.current.rotation.y += 0.001
    knotReference3.current.position.y += 0.0007

    // nervoMeshReference.current.geometry.computeVertexNormals()
    // vertexNormalsReference.current = new VertexNormalsHelper( nervoMeshReference.current, 0.01 );

    // update
    const delta = clockReference.current.getDelta()
    if (mixerReference.current) {
      mixerReference.current.update(delta)
    }

    counterReference.current += 0.5
    if (Math.round(counterReference.current) === 1) {
      plane.material.map.offset.x += 1 / 16
      if (plane.material.map.offset.x >= 1) {
        plane.material.map.offset.x = 0
        plane.material.map.offset.y -= 1 / 8
      }
      if (plane.material.map.offset.y < 0) {
        plane.material.map.offset.y = 7 / 8
      }

      plane2.material.map.offset.x += 1 / 16
      if (plane2.material.map.offset.x >= 1) {
        plane2.material.map.offset.x = 0
        plane2.material.map.offset.y -= 1 / 8
      }
      if (plane2.material.map.offset.y < 0) {
        plane2.material.map.offset.y = 7 / 8
      }
      counterReference.current = 0
    }

    let dx = camera.position.x - plane.position.x
    let dz = camera.position.z - plane.position.z
    let th = Math.atan2(dx, dz)
    plane.rotation.set(0, th, 0)
    plane2.rotation.set(0, th, 0)

    // if (!editorMode) {
    //   followPath(camera, playheadPosition / 3000, cameraCurve)
    // }
  }

  // Request Animation Frame
  useRaf(() => {
    update()
    if (effectComposerReference.current) {
      effectComposerReference.current.render()
    }
  }, isReady && isActive)

  return null
}

const NervousSystemLayingWithComposition = withComposition(NervousSystemLaying)

export default NervousSystemLayingWithComposition
